<template>
  <div class="sickinformation">
    <div class="breadcrumb">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/Patientinformation">
            <span @click="record">患者信息记录</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          查看统计数据
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="datepicker">
      <div>
        <span class="ruls">* <span style="color:#000"> 开始时间</span></span>
        <a-date-picker style="margin-left:10px" v-model="startValue" show-time placeholder="开始日期/时间"
          @openChange="handleStartOpenChange" @change="onChange" @ok="ok" value-format="YYYY-MM-DD HH:mm:ss" />
      </div>
      <div style="margin-left:20px">
        <span class="ruls">* <span style="color:#000"> 结束时间</span></span>
        <a-date-picker style="margin-left:10px" v-model="endValue" show-time placeholder="结束日期/时间" :open="endOpen"
          @openChange="handleEndOpenChange" :disabledDate="disabledDate" value-format="YYYY-MM-DD HH:mm:ss" />
      </div>
      <a-button type="primary" class="search" @click="search">
        查询
      </a-button>
    </div>
    <!-- 折线图 -->
    <div style="width:100%;height:400px;margin-bottom:60px" id="chartLineBox"></div>
    <div class="download">
      <a-button type="primary" @click="download">
        下载
      </a-button>
    </div>
    <div class="pageination">
      <ant-tabel :data="data" :pagination="pagination" :columns="columns" :loading="loading"
        recordKey="glsRecordId"></ant-tabel>
    </div>
  </div>
</template>
<script>
import excelUtil from '../../utils/dealwithExcelUtil';
import api from '@/api/index.js';
import storage from '../../storage/storage.js';
import echarts from 'echarts';
import moment from 'moment';
import AntTabel from '../Ant-tabel/Ant-tabel.vue';

const columns = [
  {
    title: '血糖(mmol/L)',
    dataIndex: 'gls',
    key: 'gls',
  },
  {
    title: 'RI速度(u/h)',
    dataIndex: 'adviseInsulinSpeed',
    key: 'adviseInsulinSpeed',
  },
  {
    title: '静脉推注',
    dataIndex: 'iv',
    key: 'iv',
  },
  {
    title: '采血部位',
    dataIndex: 'bloodCollection',
    key: 'bloodCollection',
  },
  {
    title: '监测方法',
    dataIndex: 'monitorMethod',
    key: 'monitorMethod',
  },
  { title: '日期', dataIndex: 'createTime', key: 'createTime' },
];

const data = [];
export default {
  components: { AntTabel },
  props: {},
  data() {
    return {
      sickdata: '',
      startValue: null,
      endValue: null,
      bantime: null, //禁止时间
      endOpen: false,
      flag: 1,
      columns,
      data,
      createdAt: [],
      gls: [],
      gls_speed: [],
      lv: [],
      beforeTime: '',
      nowTime: '',
      loading: true,
      patientid: '',
      listData: [],
      pagination: {
        hidePage: true
      },
    };
  },
  mounted() {
    if (this.$route.query.patientId) {
      this.patientid = this.$route.query.patientId;
    }
    //获取当前时间
    this.endValue = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.startValue = moment(new Date() - 24 * 60 * 60 * 1000).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    this.sick();
    if (this.$route.query.text) {
      this.loadLineEchart();
    }
  },
  methods: {
    //患者信息记录数据源
    sick() {
      this.data = [];
      this.createdAt = [];
      this.gls = [];
      this.gls_speed = [];

      let params = {
        patientId: this.patientid,
        startTime: this.startValue,
        endTime: this.endValue,
      }
      api
        .listGlsRecord(params)
        .then(({ data }) => {
          data.forEach((data) => {
            this.data.push(data);
            this.createdAt.push(data.createTime);
            this.gls.push(data.gls);
            this.gls_speed.push(data.adviseInsulinSpeed);
          });
          this.loadLineEchart();
          this.loading = false;
        });
    },

    //时间框
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },

    //时间框
    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    //搜索
    search() {
      if (this.startValue == null) {
        this.$message.warning('请选择开始时间');
        return;
      }
      if (this.endValue == null) {
        this.$message.warning('请选择结束时间');
        return;
      }
      if (moment(this.endValue).valueOf() < moment(this.startValue).valueOf()) {
        this.$message.warning('结束时间不能小于开始时间');
        return;
      }
      this.loading = true;
      let params = {
        patientId: this.patientid,
        startTime: this.startValue,
        endTime: this.endValue,
      }
      api
        .listGlsRecord(params)
        .then(() => {
          this.sick();
          this.loading = false;
          this.loadLineEchart();
        });
    },

    //返回上一级页面
    record() {
      storage.removeItem('sickdata');
      this.$emit('opens', true);
    },

    disabledDate(current) {
      if (this.flag == 2) {
        return (
          current &&
          current > moment(this.bantime.split(' ')[0]).add(14, 'days')
        ); //选择目前开始日期两周之后的不可选，不包括当天
      }
    },
    ok() {
      this.bantime = moment(this.startValue).format('YYYY-MM-DD HH:mm:ss');
      this.flag = 2;
    },
    onChange(date, dateString) {
      this.bantime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    },
    //折线echarts
    loadLineEchart() {
      let showed = this.data.length ? false : true;
      var myChat = echarts.init(document.getElementById('chartLineBox'));
      myChat.setOption({
        tooltip: {
          //设置tip提示
          trigger: 'axis',
        },
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
        },
        legend: {
          //设置区分（哪条线属于什么）
          orient: 'horizontal', //图例排列方式
          x: 'center', //可设定图例在左、右、居中
          y: 'bottom', //可设定图例在上、下、居中
          padding: [0, 50, 0, 0],
          data: ['血糖(mmol/L)', 'RI速度(U/H)'], //图例名称
          icon: 'line', //图例形状
          itemWidth: 30, //图例宽度
          itemHeight: 10, //图例高度
          Height: 5,
          itemGap: 20, // 设置间距
        },
        color: ['#4682B4', '#F4A460'],
        xAxis: {
          //设置x轴
          type: 'category',
          boundaryGap: false, //坐标轴两边不留白
          data: this.createdAt,
          nameTextStyle: {
            //坐标轴名称的文字样式
            color: '#FA6F53',
            fontSize: 16,
            padding: [0, 0, 0, 20],
          },
          axisTick: { show: false }, // X轴刻度
          axisLine: {
            show: true,
            //坐标轴轴线相关设置。
            lineStyle: {
              color: '#808080',
            },
          },
        },
        yAxis: {
          //Y轴线条
          nameTextStyle: {
            color: '#FA6F53',
            fontSize: 16,
            padding: [0, 0, 10, 0],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#808080',
            },
          },
          axisTick: { show: false }, // Y轴刻度
          type: 'value',
          splitLine: {
            //网格线
            show: false,
          },
        },
        series: [
          {
            // symbol: 'none', //不显示折线原点
            name: '血糖(mmol/L)',
            data: this.gls,
            type: 'line', // 类型为折线图
            stack: '血糖(mmol/L',
            lineStyle: {
              normal: {
                color: '#4682B4',
                width: 5,
              },
            },
          },
          {
            // symbol: 'none', //不显示折线原点
            name: 'RI速度(U/H)',
            data: this.gls_speed,
            type: 'line',
            stack: 'RI速度(U/H)',
            lineStyle: {
              normal: {
                color: '#F4A460',
                width: 5,
              },
            },
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChat.resize();
      });
    },

    //下载
    async download() {
      this.listData = [];
      let params = {
        patientId: this.patientid,
        startTime: this.startValue,
        endTime: this.endValue,
      }

      await api.listGlsRecord(params).then(({ data }) => {
        this.listData = data;
        data.forEach((item) => {
          item.iv = item.iv || '';
        })
      });
      if (this.listData.length > 0) {
        excelUtil.exportExcel(
          this.columns,
          this.listData,
          '患者信息数据统计.xlsx'
        );
      } else {
        this.$message.warning('暂无数据');
      }
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  margin-top: 15px;
}

.breadcrumb>>>.ant-breadcrumb {
  font-size: 18px;
}

.datepicker {
  display: flex;
  margin-top: 20px;
}

.datepicker .ruls {
  font-size: 15px;
  color: red;
}

.search {
  margin-left: 30px;
}

.download {
  flex: 1;
  text-align: right;
}
</style>
